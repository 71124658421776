<template>
  <a-space direction="vertical" size="large">
    <a-page-header title="用户管理" sub-title="" @back="() => null" />
    <!-- <div class="search-area">
       <a-button type="link" icon="plus" @click="handleEdit">添加用户</a-button>
    </div> -->
    <a-input-group compact>
      <a-select v-model="search.is_verify" default-value="" style="width: 10%">
        <a-select-option value="1"> 已认证 </a-select-option>
        <a-select-option value="0"> 未认证 </a-select-option>
        <a-select-option value=""> 所有 </a-select-option>
      </a-select>
      <a-input
        addon-before="用户 ID"
        style="width: 20%"
        v-model="search.user_id"
        default-value=""
      />
      <a-input
        addon-before="姓名"
        style="width: 20%"
        v-model="search.nickname"
        default-value=""
      />
      <a-input
        addon-before="住址"
        style="width: 20%"
        v-model="search.location"
        default-value=""
      />
      <a-button @click="handleSearch" type="primary">搜索</a-button>
    </a-input-group>

    <!-- <a-input-search
      placeholder="input search text"
      enter-button="Search"
      size="large"
      @search="handleSearch"
    /> -->
    <a-table
      rowKey="user_id"
      :columns="columns"
      :data-source="data"
      :scroll="{ y: 500 }"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <span slot="indexNum" slot-scope="item, record, index">
        {{ index + 1 }}
      </span>
      <span slot="gender" slot-scope="item">
        {{ USER_GENDER_MAP[item] }}
      </span>
      <span slot="datetime" slot-scope="item">
        {{ new Date(item * 1000).toLocaleDateString() }}
      </span>
      <span slot="is_verify" slot-scope="text, record">
        {{ record.is_verify ? "已认证" : "" }}
      </span>
      <span slot="action" slot-scope="text, record">
        <div class="btns-column">
          <a-button
            @click="
              () => {
                handleEdit(record);
              }
            "
            :id="record.user_id"
            type="primary"
            icon="edit"
            >编辑</a-button
          >
          <a-button
            @click="
              () => {
                openMaintainRecord(record);
              }
            "
            :id="record.user_id"
            type="primary"
            >维修记录</a-button
          >
        </div>
      </span>
    </a-table>
    <EditUser
      :visible="showEditor"
      :initForm="editForm"
      @close="handleEditorClose"
      @confirm="handleEditorConfirm"
    ></EditUser>
  </a-space>
</template>


<script>
import EditUser from "../components/EditUser.vue";
import { request } from "@utils";
import { USER_GENDER_MAP } from "@/consts.js";

const columns = [
  {
    title: "用户 ID",
    dataIndex: "user_id",
    key: "user_id",
    ellipsis: true,
    width: 200,
  },
  {
    title: "认证",
    dataIndex: "is_verify",
    key: "is_verify",
    width: 80,
    scopedSlots: { customRender: "is_verify" },
  },

  {
    title: "姓名",
    dataIndex: "nickname",
    key: "nickname",
    ellipsis: true,
    width: 100,
  },
  {
    title: "年龄",
    dataIndex: "year_old",
    key: "year_old",
    width: 80,
  },
  {
    title: "性别",
    dataIndex: "gender",
    key: "gender",
    width: 80,
    scopedSlots: { customRender: "gender" },
  },
  {
    title: "联系方式",
    dataIndex: "mobile",
    key: "mobile",
    width: 150,
  },
  {
    title: "地址",
    dataIndex: "location",
    key: "location",
    ellipsis: true,
    width: 200,
  },
  {
    title: "备注",
    dataIndex: "message",
    key: "message",
    ellipsis: true,
    width: 100,
  },
  {
    title: "创建时间",
    dataIndex: "created_at",
    key: "created_at",
    ellipsis: true,
    width: 100,
    scopedSlots: { customRender: "datetime" },
  },
  {
    key: "action",
    align: "right",
    width: 140,
    fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  name: "UserManage",
  components: {
    EditUser,
  },
  mounted() {
    this.refresh();
  },
  data() {
    return {
      USER_GENDER_MAP,
      data: [],
      columns,
      search: {
        is_verify: "",
        user_id: "",
        nickname: "",
        location: "",
      },
      showEditor: false,
      editForm: {},
      pagination: {
        pageSize: 20,
        current: 1,
        total: 0,
      },
    };
  },
  methods: {
    openMaintainRecord(record) {
      window.location = "/#/maintain/" + record.user_id;
    },
    handleRemoveBtn() {},
    handleEditorConfirm() {
      this.showEditor = false;
      this.refresh();
    },
    handleEditorClose() {
      this.showEditor = false;
      this.refresh();
    },
    handleEdit(record) {
      this.editForm = {
        ...record,
      };
      this.showEditor = true;
    },
    handleEditVerify(record) {},
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;

      this.refresh(
        pagination.pageSize * (pagination.current - 1),
        pagination.pageSize
      );
    },
    handleSearch() {
      this.refresh(0, 10, {
        ...this.search,
      });
    },
    async refresh(offset = 0, limit = 10, search) {
      const res = await request.get(
        `/api/cms/v1/users?offset=${offset}&limit=${limit}`,
        search
      );
      this.data = [];
      const year = new Date().getFullYear();
      for (let item of res.data.data.list) {
        this.data.push({
          ...item,
          year_old:
            item.birthday > 0
              ? year - new Date(item.birthday * 1000).getFullYear()
              : 0,
        });
      }
      this.pagination.total = res.data.data.count
    },
  },
};
</script>

<style scoped>
.search-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btns-column button {
  margin: 5px;
}
</style>