const PayTypeDefault = 0
const PayTypeWechat = 1
const PayTypeBalance = 2
const PayTypeMixed = 3
const PayTypeAlipay = 4
const PayTypeCash = 5

const PayTypeMap = {
  [PayTypeDefault]: "待支付",
  [PayTypeWechat]: "微信支付",
  [PayTypeBalance]: "余额支付",
  [PayTypeMixed]: "混合支付",
  [PayTypeAlipay]: "支付宝支付",
  [PayTypeCash]: "现金支付",
}
const OrderStatusUnpaid = 0
const OrderStatusPaid = 1
const OrderStatusRefund = 2
const OrderStatusCancel = 3

const OrderStatusMap = {
  [OrderStatusUnpaid]: "待付款",
  [OrderStatusPaid]: "已付款",
  [OrderStatusCancel]: "已取消",
  [OrderStatusRefund]: "已退款",
}

const MAINTAIN_STATUS_MAP = {
  0: "已预约",
  1: "已完成",
  2: "已延期",
  3: "待预约"
}

const BOUND_STATUS_MAP = {
  0: "正常",
  1: "异常",
  2: "故障",
  3: "超期",
}

const CUSTOMER_STATUS_MAP = {
  0: "已预约",
  1: "待预约",
  2: "已完成",
  3: "已失联"
}

const USER_GENDER_MAP = {
  0: "未知",
  1: "男",
  2: "女",
}


export {
  OrderStatusPaid,
  OrderStatusCancel,
  OrderStatusRefund,
  OrderStatusMap,

  PayTypeAlipay,
  PayTypeDefault,
  PayTypeWechat,
  PayTypeMixed,
  PayTypeBalance,
  PayTypeCash,
  PayTypeMap,

  MAINTAIN_STATUS_MAP,
  BOUND_STATUS_MAP,
  CUSTOMER_STATUS_MAP,
  USER_GENDER_MAP
}
