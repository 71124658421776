<template>
  <a-space direction="vertical" size="large">
    <a-page-header title="绑定管理" sub-title="" @back="() => null" />
    <a-input-group compact>
      <a-input
        addon-before="用户 ID"
        style="width: 30%"
        v-model="search.user_id"
        default-value=""
      />
      <a-input
        addon-before="设备 ID"
        style="width: 30%"
        v-model="search.machine_id"
        default-value=""
      />
      <a-button @click="handleSearch" type="primary">搜索</a-button>
    </a-input-group>
    <a-table
      rowKey="id"
      :columns="columns"
      :data-source="data"
      :scroll="{ y: 500 }"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <span slot="indexNum" slot-scope="item, record, index">
        {{ index + 1 }}
      </span>
      <span slot="status" slot-scope="item">
        {{ BOUND_STATUS_MAP[item] }}
      </span>
      <span slot="datetime" slot-scope="item">
        {{ new Date(item * 1000).toLocaleDateString() }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a-button
          @click="
            () => {
              handleEdit(record);
            }
          "
          :id="record.id"
          type="primary"
          icon="edit"
          >编辑</a-button
        >
      </span>
    </a-table>
    <EditBound
      :visible="showEditor"
      :initForm="editForm"
      @close="handleEditorClose"
      @confirm="handleEditorConfirm"
    ></EditBound>
  </a-space>
</template>


<script>
import { request } from "@utils";
import EditBound from "../components/EditBound.vue";
import { BOUND_STATUS_MAP } from "@/consts.js";

const columns = [
  {
    title: "绑定 ID",
    dataIndex: "id",
    key: "id",
    ellipsis: true,
    width: 100,
  },
  {
    title: "用户名称",
    dataIndex: "user.nickname",
    key: "user.nickname",
    ellipsis: true,
    width: 100,
  },
  {
    title: "设备名称",
    dataIndex: "machine.name",
    key: "machine.name",
    ellipsis: true,
    width: 100,
  },
   {
    title: "备注名称",
    dataIndex: "machine_name",
    key: "machine_name",
    ellipsis: true,
    width: 180,
  },
  {
    title: "状态",
    dataIndex: "status",
    key: "status",
    width: 100,
    scopedSlots: { customRender: "status" },
  },
  {
    title: "是否解绑",
    dataIndex: "is_delete",
    key: "is_delete",
    width: 100,
  },
  {
    title: "下次维护时间",
    dataIndex: "next_maintain_at",
    key: "next_maintain_at",
    width: 100,
    scopedSlots: { customRender: "datetime" },
  },
  {
    title: "绑定时间",
    dataIndex: "created_at",
    key: "created_at",
    width: 100,
    scopedSlots: { customRender: "datetime" },
  },
  {
    key: "action",
    align: "right",
    width: 120,
    fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  name: "BoundMange",
  components: {
    EditBound,
  },
  mounted() {
    this.refresh();
  },
  data() {
    return {
      BOUND_STATUS_MAP,
      data: [],
      columns,
      search: {
        user_id: "",
        machine_id: "",
      },
      showEditor: false,
      editForm: {},
      pagination: {
        pageSize: 20,
        current: 1,
        total: 0,
      },
    };
  },
  methods: {
    handleRemoveBtn() {},
    handleEditorConfirm() {
      this.showEditor = false;
      this.refresh();
    },
    handleEditorClose() {
      this.showEditor = false;
    },
    handleEdit(record) {
      this.editForm = {
        id: record.id,
        nickname: record.user.nickname,
        ...record,
      };
      console.log(record);
      this.showEditor = true;
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;

      this.refresh(
        pagination.pageSize * (pagination.current - 1),
        pagination.pageSize
      );
    },
    handleSearch() {
      this.refresh(0, 10, {
        ...this.search,
      });
    },
    async refresh(offset = 0, limit = 10, search) {
      const res = await request.get(
        `/api/cms/v1/machines/bound?offset=${offset}&limit=${limit}`,
        search
      );
      this.data = [];
      for (let item of res.data.data.list) {
        this.data.push({
          ...item,
        });
      }
      this.pagination.total = res.data.data.count
    },
  },
};
</script>

<style scoped>
.search-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>