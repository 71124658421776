<template>
  <a-layout>
    <a-layout-header class="header">
      <ContentHeader></ContentHeader>
    </a-layout-header>
    <a-layout>
      <a-layout-sider
        class="navi-wrapper"
        v-model="collapsed"
        :trigger="null"
        collapsible
      >
        <Navi collapsed="menu-fold" @change="handleNavChange"></Navi>
      </a-layout-sider>
      <a-layout>
        <a-layout-content class="content">
          <router-view></router-view>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-layout>
</template>

<script>
import ContentHeader from "../components/ContentHeader.vue";
import Navi from "../components/Navi.vue";

export default {
  name: "Index",
  components: {
    ContentHeader,
    Navi,
  },
  data() {
    return {
      collapsed: false,
      choosen: "user",
    };
  },
  methods: {
    handleNavChange(tab) {
      // console.log(tab);
      this.choosen = tab;
    },
  },
};
</script>

<style scoped >
.content {
  margin: 30px;
  padding: 30px;
  background: white;
  /* min-height: 280px; */
  /* height: 100%; */
}
.header {
  height: 80px;
  width: 100%;
  padding: 0;
  margin: 0;
}
.navi-wrapper {
  margin: 30px 0 30px 30px;
  padding: 0;
  background: white;
}
</style>
