import axios from 'axios'
import storage from './storage'

const setup = function () {
  axios.interceptors.response.use((response) => {
    return response
  }, (e) => {
    e.response.error = {
      title: `网络异常 ${e.response.status}`,
      content: `${e.response.statusText}: ` + JSON.stringify(e.response.data),
    }
    return Promise.reject(e)
  })

}

setup()

export default {
  get(url, params) {
    return this.request(url, params,null, 'GET')
  },
  post(url, params, data) {
    return this.request(url, params,data, 'POST')
  },
  put(url, params, data) {
    return this.request(url, params,data, 'PUT')
  },
  delete(url, params, data) {
    return this.request(url, params,data, 'DELETE')
  },
  request(url, params, data, method) {
    const { token = '' } = storage.Get('login') || {}
    return axios({
      method,
      url,
      params,
      data: data,
      headers:
      {
        'Content-Type': 'application/json',
        authorization: token
      },
    })
  }
  
}