<template>
  <a-menu
    class="navi"
    mode="inline"
    @click="handleClick"
    :default-selected-keys="defaultSelectedKeys"
  >
    <!-- <a-menu-item key="purchase" class="navi-item">
      <a-icon type="shopping-cart" /> 购买结算
    </a-menu-item> -->
     <a-menu-item key="customer" class="navi-item">
        <a-icon type="user" />客户资料管理
      </a-menu-item>
    <a-menu-item key="maintain" class="navi-item">
      <a-icon type="history" />维修记录
    </a-menu-item>
    <a-sub-menu key="weapp"  :open-keys="openKeys">
      <span slot="title"><a-icon type="appstore" /><span>小程序数据管理</span></span>
      <a-menu-item key="user" class="navi-item">
        <a-icon type="user" />用户管理
      </a-menu-item>
      <a-menu-item key="machine" class="navi-item">
        <a-icon type="database" />设备管理
      </a-menu-item>
      <a-menu-item key="bound" class="navi-item">
        <a-icon type="api" />绑定管理
      </a-menu-item>
     
    </a-sub-menu>
   
  </a-menu>
</template>

<script>
export default {
  name: "navi",
  data() {
    return {
      openKeys: null,
      defaultSelectedKeys: [],
    };
  },
  beforeMount(){
    this.defaultSelectedKeys = [this.$route.name]
  },
  methods: {
    handleClick(e) {
      this.openKeys = e.key
      // console.log(e.key);
      window.location="/#/" + e.key
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang='less'>
.navi {
  border-right: none;
}
.navi-item {
  height: 55px;
  line-height: 55px;

  &::after {
    left: 0;
    right: auto;
  }
}
</style>
