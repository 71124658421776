<template>
  <a-space direction="vertical" size="large">
    <a-page-header title="设备管理" sub-title="" @back="() => null" />
    <div class="search-area">
      <a-button type="link" icon="plus" @click="handleCreate"
        >添加商品</a-button
      >
    </div>
    <a-input-group compact>
      <a-input
        addon-before="设备 ID"
        style="width: 30%"
        v-model="search.machine_id"
        default-value=""
      />
      <a-input
        addon-before="设备名称"
        style="width: 30%"
        v-model="search.name"
        default-value=""
      />
      <a-button @click="handleSearch" type="primary">搜索</a-button>
    </a-input-group>
    <a-table
      rowKey="id"
      :columns="columns"
      :data-source="data"
      :scroll="{ y: 500 }"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <span slot="indexNum" slot-scope="item, record, index">
        {{ index + 1 }}
      </span>
      <span slot="datetime" slot-scope="item">
        {{ new Date(item * 1000).toLocaleDateString() }}
      </span>
      <div slot="action" slot-scope="text, record">
        <div class="btns-column">
          <a-button
            @click="
              () => {
                handleEdit(record);
              }
            "
            :id="record.id"
            type="primary"
            >编辑</a-button
          >
          <a-button
            type="primary"
            @click="
              () => {
                handlePrint(record);
              }
            "
            >打印</a-button
          >
        </div>
      </div>
    </a-table>
    <EditMachine
      :visible="showEditor"
      :initForm="editForm"
      @close="handleEditorClose"
      @confirm="handleEditorConfirm"
    ></EditMachine>
  </a-space>
</template>


<script>
import { request } from "@utils";
import EditMachine from "../components/EditMachine.vue";

const columns = [
  {
    title: "设备 ID",
    dataIndex: "machine_id",
    key: "machine_id",
    width: 200,
    ellipsis: true,
  },
  {
    title: "名称",
    dataIndex: "name",
    key: "name",
    ellipsis: true,
    width: "10vw",
  },
  {
    title: "型号",
    dataIndex: "model",
    key: "model",
    ellipsis: true,
  },
  {
    title: "规格",
    dataIndex: "schema",
    key: "schema",
    ellipsis: true,
  },
  {
    title: "信息",
    dataIndex: "information",
    key: "information",
    ellipsis: true,
  },
  {
    title: "状态",
    dataIndex: "status",
    key: "status",
    width: 80,
  },
  {
    title: "价格",
    dataIndex: "price",
    key: "price",
    width: 80,
  },
  {
    title: "创建时间",
    dataIndex: "created_at",
    key: "created_at",
    ellipsis: true,
    width: 100,
    scopedSlots: { customRender: "datetime" },
  },
  {
    key: "action",
    align: "right",
    width: 120,
    fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  name: "MachineManage",
  components: {
    EditMachine,
  },
  mounted() {
    this.refresh();
  },
  data() {
    return {
      data: [],
      columns,
      search: {
        machine_id: "",
        name: "",
      },
      showEditor: false,
      editForm: {},
      pagination: {
        pageSize: 20,
        current: 1,
        total: 0,
      },
    };
  },
  methods: {
    handlePrint(record) {
      window.open(`/#/print/${record.machine_id}`);
    },
    handleCreate() {
      this.editForm = {};
      this.showEditor = true;
    },
    handleRemoveBtn() {},
    handleEditorConfirm() {
      this.showEditor = false;
      this.refresh();
    },
    handleEditorClose() {
      this.showEditor = false;
    },
    handleEdit(record) {
      this.editForm = {
        ...record,
      };
      console.log(record);
      this.showEditor = true;
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;

      this.refresh(
        pagination.pageSize * (pagination.current - 1),
        pagination.pageSize
      );
    },
    handleSearch() {
      this.refresh(0, 10, {
        ...this.search,
      });
    },
    async refresh(offset = 0, limit = 10, search) {
      const res = await request.get(
        `/api/cms/v1/machines?offset=${offset}&limit=${limit}`,
        search
      );
      this.data = [];
      for (let item of res.data.data.list) {
        this.data.push({
          ...item,
        });
      }
      this.pagination.total = res.data.data.count
    },
  },
};
</script>

<style scoped>
.search-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btns-column button {
  margin: 5px;
}
</style>