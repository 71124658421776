import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import App from './App.vue'
import Antd from 'ant-design-vue';


import 'ant-design-vue/dist/antd.less';
import store from './store'
import routes from './routes'

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(Antd)
// Vue.use(Antd.FormModel)
Vue.config.productionTip = false


new Vue({
  render: h => h(App),
  store: new Vuex.Store(store),
  router: new VueRouter({ routes })
}).$mount('#app')
