<template>
  <a-modal
    title="用户信息"
    :visible="visible"
    :confirm-loading="confirmLoading"
    okText="确认"
    cancelText="取消"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form-model
      ref="skuForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="客户 ID" prop="customer_id">
        <a-input disabled v-model="form.customer_id" />
      </a-form-model-item>
      <a-form-model-item label="状态" prop="status">
        <a-radio-group v-model="form.status">
          <a-radio-button
            v-for="item in [0, 1, 2, 3]"
            :key="item"
            :value="item"
          >
            {{ MAINTAIN_STATUS_MAP[item] }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="内容" required prop="content">
        <a-textarea v-model="form.content" />
      </a-form-model-item>
      <a-form-model-item label="跟进人" required prop="operator">
        <a-input v-model="form.operator" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { request } from "@utils";
import { storage } from "@utils";
import { MAINTAIN_STATUS_MAP } from "@/consts.js";
const EMPTY = {
  user_id: "",
  bound_id: "",
  machine_id: "",
  content: "",
  operator: 0,
};

export default {
  props: {
    visible: Boolean,
    initForm: Object,
  },
  components: {},
  watch: {
    visible: function (nv, ov) {
      console.log(nv, ov, this.initForm);
      const { token = "" } = storage.Get("login") || {};
      if (nv == true && ov == false) {
        this.$refs.skuForm?.clearValidate();
        if (this.initForm) {
          this.form = {
            ...this.initForm,
            status: "" + this.initForm.status,
          };
        }
      }
    },
  },
  data() {
    return {
      MAINTAIN_STATUS_MAP,
      confirmLoading: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      qrcode: "",
      form: {
        ...EMPTY,
      },
      rules: {
        // name: [{ required: true, message: "请填写商品名称" }],
        // goods_code: [{ required: true, message: "请填写商品代码" }],
        // price: [{ required: true, message: "请给商品定个价" }],
      },
    };
  },
  methods: {
    handlePrint() {
      window.open(`/#/print/${this.initForm.machine_id}`);
    },
    handleGetSkuInfo(code) {
      // 商品码
      this.form.goods_code = code;
    },
    handleOk(e) {
      this.confirmLoading = true;
      this.$refs.skuForm.validate(async (valid) => {
        if (valid) {
          const isEdit = !!this.form.id;
          let mutable = {};
          if (isEdit) {
            Object.keys(this.form).forEach((key) => {
              console.log(key, this.form[key], this.initForm[key]);
              if (this.form[key] != this.initForm[key]) {
                mutable[key] = this.form[key];
              }
            });
          } else {
            mutable = {
              ...this.form,
            };
          }

          const body = {
            ...mutable,
            status: mutable.status ? +mutable.status : undefined,
          };
          if (isEdit) {
            await request.put(
              `/api/cms/v1/maintains/${this.form.id}`,
              {},
              body
            );
          } else {
            await request.post("/api/cms/v1/maintains", {}, body);
          }

          this.form = { ...EMPTY };
          this.$emit("confirm");
        }
        this.confirmLoading = false;
      });
    },
    handleCancel(e) {
      this.$emit("close");
    },
  },
};
</script>