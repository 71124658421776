<template>
  <div class="com-header">
    <img class="logo" src="../assets/logo.png" />
    <div class="span"></div>
    <div class="header-right">
      <div>
        <a-icon slot="prefix" type="user" style="color: #fff" />
        <span> {{ userInfo.name }}</span>
        <a-divider type="vertical" />
        <a @click="handleLogout">退出登录</a>
      </div>
    </div>
  </div>
</template>

<script>
import { request, storage } from "@utils";

export default {
  name: "ContentHeader",
  props: {
    msg: String,
  },
  computed: {
    userInfo: function () {
      return this.$store.state.userInfo;
    },
  },
  methods: {
    handleLogout() {
      this.$store.commit("login", null);
      storage.Delete("login");
      location.reload();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang='less'>
.com-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  height: 80px;
  padding: 0 30px;
}
.com-header .span {
  flex: 1;
}
.com-header .logo {
  width: 130px;
}
.com-header .header-right {
  color: #aaa;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
