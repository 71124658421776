<template>
  <a-space direction="vertical" size="large">
    <a-page-header title="客户管理" sub-title="" @back="() => null" />
    <div class="search-area">
       <a-button type="link" icon="plus" @click="handleEdit">添加客户</a-button>
       <a-button type="link" icon="export" @click="handleExport">导出资料</a-button>
    </div>
    <a-input-group compact>
      <a-select v-model="search.status" default-value="" style="width: 10%">
        <a-select-option value=""  > 所有 </a-select-option>
        <a-select-option  v-for="item in [0, 1, 2, 3]"
            :key="item"
            :value="item" > {{CUSTOMER_STATUS_MAP[item]}} </a-select-option>
        
      </a-select>
      <a-input
        addon-before="客户 ID"
        style="width: 20%"
        v-model="search.customer_id"
        default-value=""
      />
      <a-input
        addon-before="姓名"
        style="width: 20%"
        v-model="search.full_name"
        default-value=""
      />
      <a-input
        addon-before="住址"
        style="width: 20%"
        v-model="search.address"
        default-value=""
      />
      <a-button @click="handleSearch" type="primary">搜索</a-button>
    </a-input-group>

    <!-- <a-input-search
      placeholder="input search text"
      enter-button="Search"
      size="large"
      @search="handleSearch"
    /> -->
    <a-table
      rowKey="id"
      :columns="columns"
      :data-source="data"
      :scroll="{ y: 500 }"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <span slot="indexNum" slot-scope="item, record, index">
        {{ index + 1 }}
      </span>
      <span slot="gender" slot-scope="item">
        {{ USER_GENDER_MAP[item] }}
      </span>
      <span slot="datetime" slot-scope="item">
        {{ new Date(item).toLocaleDateString() }}
      </span>
      <span slot="status" slot-scope="text, record">
        {{ CUSTOMER_STATUS_MAP[record.status]}}
      </span>
      <span slot="action" slot-scope="text, record">
        <div class="btns-column">
          <a-button
            @click="
              () => {
                handleEdit(record);
              }
            "
            :id="record.user_id"
            type="primary"
            icon="edit"
            >编辑</a-button
          >
          <a-button
            @click="
              () => {
                openMaintainRecord(record);
              }
            "
            :id="record.id"
            type="primary"
            >维修记录</a-button
          >
        </div>
      </span>
    </a-table>
    <EditCustomer
      :visible="showEditor"
      :initForm="editForm"
      @close="handleEditorClose"
      @confirm="handleEditorConfirm"
    ></EditCustomer>
  </a-space>
</template>


<script>
import EditCustomer from "../components/EditCustomer.vue";
import { request, storage } from "@utils";
import { USER_GENDER_MAP, CUSTOMER_STATUS_MAP } from "@/consts.js";

const columns = [
  {
    title: "用户 ID",
    dataIndex: "user_id",
    key: "user_id",
    ellipsis: true,
    width: 100,
  },
  
  {
    title: "姓名",
    dataIndex: "full_name",
    key: "full_name",
    ellipsis: true,
    width: 100,
  },
  {
    title: "年龄",
    dataIndex: "year_old",
    key: "year_old",
    width: 80,
  },
  {
    title: "性别",
    dataIndex: "gender",
    key: "gender",
    width: 80,
    scopedSlots: { customRender: "gender" },
  },
  {
    title: "联系方式",
    dataIndex: "contact",
    key: "contact",
    width: 150,
  },
  {
    title: "地址",
    dataIndex: "address",
    key: "address",
    ellipsis: true,
    width: 200,
  },
   {
    title: "状态",
    dataIndex: "status",
    key: "status",
    width: 100,
    scopedSlots: { customRender: "status" },
  },
  {
    title: "备注",
    dataIndex: "remark",
    key: "remark",
    ellipsis: true,
    width: 100,
  },
  {
    title: "下次维护时间",
    dataIndex: "next_maintain_at",
    key: "next_maintain_at",
    ellipsis: true,
    width: 100,
    scopedSlots: { customRender: "datetime" },
  },
  {
    key: "action",
    align: "right",
    width: 140,
    fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  name: "CustomerManage",
  components: {
    EditCustomer,
  },
  mounted() {
    this.refresh();
  },
  data() {
    return {
      USER_GENDER_MAP,
      CUSTOMER_STATUS_MAP,
      data: [],
      columns,
      search: {
        is_verify: "",
        user_id: "",
        nickname: "",
        location: "",
      },
      showEditor: false,
      editForm: {},
      pagination: {
        pageSize: 20,
        total: 40,
      },
    };
  },
  methods: {
    handleExport(){
      const { token = "" } = storage.Get("login") || {};
       window.location = "/api/cms/v1/customers/excel/export?token=" + token
    },
    openMaintainRecord(record) {
      window.location = "/#/maintain/" + record.id;
    },
    handleRemoveBtn() {},
    handleEditorConfirm() {
      this.showEditor = false;
      this.refresh();
    },
    handleEditorClose() {
      this.showEditor = false;
      this.refresh();
    },
    handleEdit(record) {
      this.editForm = {
        ...record,
      };
      this.showEditor = true;
    },
    handleEditVerify(record) {},
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;

      this.refresh(
        pagination.pageSize * (pagination.current - 1),
        pagination.pageSize
      );
    },
    handleSearch() {
      this.refresh(0, 10, {
        ...this.search,
      });
    },
    async refresh(offset = 0, limit = 10, search) {
      const res = await request.get(
        `/api/cms/v1/customers?offset=${offset}&limit=${limit}`,
        search
      );
      this.data = [];
      const year = new Date().getFullYear();
      for (let item of res.data.data.list) {
        this.data.push({
          ...item,
          year_old:
            item.birthday > 0
              ? year - new Date(item.birthday * 1000).getFullYear()
              : 0,
        });
      }
      console.log(this.pagination)
      this.pagination.total = res.data.data.count
    },
  },
};
</script>

<style scoped>
.search-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btns-column button {
  margin: 5px;
}
</style>