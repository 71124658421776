<template>
  <div style="background: #fff">
    <div class="card" v-for="item in [1, 2, 3, 4]" v-bind:key="item">
      <p class="title">香河净界净水设备卡</p>
      <p class="desc">打开微信小程序「净界净水」</p>
      <p class="desc">扫描此设备码进行绑定</p>
      <img class="qrcode" :src="qrcode" />
      <p class="machine-name">{{ data.name }}</p>
      <!-- <p class="machine-name">{{data.schema}}</p> -->
    </div>
  </div>
</template>

<script>
import { request } from "@utils";
import { storage } from "@utils";

export default {
  name: "Print",
  components: {},
  props: {
    machine_id: String,
  },
  data() {
    return {
      data: {},
      qrcode: "",
    };
  },
  mounted() {
    this.refresh();
  },
  computed: {},
  methods: {
    async refresh() {
      const { token = "" } = storage.Get("login") || {};
      const res = await request.get(`/api/cms/v1/machines/${this.machine_id}`);
      this.data = res.data.data;
      this.qrcode = `https://watermall.todoso.cn/api/cms/v1/machines/${this.machine_id}/qrcode?token=${token}`;
    },
  },
};
</script>

<style scoped lang="less">
.card {
  text-align: center;
  font-size: 24px;
  background: #fff;
  width: 250px;
  float: left;
  margin: 50px;
}
.title {
  font-size: 20px;
}
.desc {
  font-size: 16px;
}
.qrcode {
  width: 200px;
  height: 200px;
  display: block;
  margin: 0 auto;
}
.machine-name {
  font-size: 18px;
}
</style>
