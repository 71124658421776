
import Login from './views/Login.vue'
import Index from './views/Index.vue'
import Print from './views/Print.vue'
import UserManage from './views/UserManage.vue'
import CustomerManage from './views/CustomerManage.vue'

import MachineManage from './views/MachineManage.vue'
import BoundManage from './views/BoundManage.vue'

import Maintain from './views/MaintainManage.vue'

export default [
    // 动态路径参数 以冒号开头
    { path: '/', component: Login },
    // { path: '/index', component: Index},
    { name:'customer', path: '/customer', component: CustomerManage },
    {name:'user', path: '/user', component: UserManage },
    { name:'machine',path: '/machine', component: MachineManage },
    { name:'bound',path: '/bound', component: BoundManage },

    {name:'print', path: '/print/:machine_id', component: Print, props: true },
    {name:'maintain', path: '/maintain/:customer_id', component: Maintain, props: true },
    { name:'maintain',path: '/maintain', component: Maintain },
]