<template>
  <div id="app">
    <div v-if="isPrint">
      <router-view></router-view>
    </div>
    <template v-if="isNormal">
      <Index v-if="isLogin"></Index>
      <Login v-else></Login>
    </template>
  </div>
</template>

<style scoped>
#app {
  height: 100%;
  display: flex;
}
</style>

<script>
import { storage } from "@utils";
import Index from "@views/Index";
import Login from "@views/Login";

export default {
  name: "App",
  components: {
    Index,
    Login,
  },
  data() {
    return {
      isNormal: true,
      isPrint: false,
      isLogin: false,
    };
  },
  created() {
    if (this.$router.currentRoute.path.indexOf("/print/") == 0) {
      this.isPrint = true;
      this.isNormal = false;
    }
    const { token } = storage.Get("login");
    if (token) {
      this.isLogin = true;
    }
    if (!this.$store.state.userInfo.id) {
      const userInfo = storage.Get("login");
      this.$store.commit("login", userInfo);
      if (!userInfo && this.$router.currentRoute.path !== "/") {
        this.$router.push("/");
      }
    }
  },
};
</script>

<style scoped>
</style>