<template>
  <a-modal
    title="用户信息"
     :visible="visible"
    :confirm-loading="confirmLoading"
    okText="确认"
    cancelText="取消"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form-model
      ref="skuForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="设备 ID"  prop="machine_id">
        <a-input disabled v-model="form.machine_id" />
      </a-form-model-item>
      <a-form-model-item label="设备名" required prop="name">
        <a-input  v-model="form.name" />
      </a-form-model-item>
        <a-form-model-item label="型号" required prop="model">
        <a-input  v-model="form.model" />
      </a-form-model-item>
       <a-form-model-item label="规格" required prop="schema">
        <a-textarea  v-model="form.schema" />
      </a-form-model-item>
        <a-form-model-item label="信息" required prop="information">
        <a-textarea  v-model="form.information" />
      </a-form-model-item>
      <a-form-model-item label="售价" required prop="price">
        <a-input type="number" v-model="form.price" />
      </a-form-model-item>
       <a-form-model-item label="二维码" >
        <div  v-if="!!qrcode" >
          <img :src="qrcode"> 
          <a-button @click="handlePrint">打印</a-button>
        </div>
        <div v-else >无</div>
      </a-form-model-item>
         <!-- <a-form-model-item label="状态"  prop="status">
        <a-input type="number" v-model="form.status" />
      </a-form-model-item> -->
     
    </a-form-model>
   
  </a-modal>
</template>
<script>
import { request } from "@utils";
import { storage } from "@utils"
const EMPTY = {
  machine_id: "",
  name: "",
  satus: 0, 
  model: '',
  schema: '',
  information: '', 
  price: 0
};

export default {
  props: {
    visible: Boolean,
    initForm: Object,
  },
  components:{
    
  },
  watch:{
    visible: function(nv, ov) {
      console.log(nv, ov, this.initForm)
      const { token = '' } = storage.Get('login') || {}
      if ( nv == true && ov == false) {
        this.$refs.skuForm?.clearValidate();
        if (this.initForm) {
          this.form = {
            ...this.initForm
          }
        }
        if(this.form.machine_id) {
          this.qrcode = `https://watermall.todoso.cn/api/cms/v1/machines/${this.form.machine_id}/qrcode?token=${token}`
        }
        
        
      }
    }
  },
  data() {
    return {
      confirmLoading: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      qrcode: "",
      form: {
        ...EMPTY,
      },
      rules: {
        // name: [{ required: true, message: "请填写商品名称" }],
        // goods_code: [{ required: true, message: "请填写商品代码" }],
        // price: [{ required: true, message: "请给商品定个价" }],
      },
    };
  },
  methods: {
    handlePrint(){
      window.open(`/#/print/${this.initForm.machine_id}`)
    },
    handleGetSkuInfo(code) {
      // 商品码
      this.form.goods_code = code
    },
    handleOk(e) {
      this.confirmLoading = true;
      this.$refs.skuForm.validate(async (valid) => {
        if (valid) {
          const isEdit = !!this.form.machine_id
          let mutable = {}
          Object.keys(this.form).forEach((key) => {
            console.log(key, this.form[key], this.initForm[key])
            if (this.form[key] != this.initForm[key]) {
              mutable[key] = this.form[key]
            }
          })
          const body =   {
              ...mutable,
            price: mutable.price === undefined ? undefined : +mutable.price,
            status: mutable.status === undefined ? undefined : +mutable.status,
          }
          if (isEdit){
              await request.put(
                `/api/cms/v1/machines/${this.form.machine_id}`,
                {},
                body
            );
          } else {
             await request.post(
            "/api/cms/v1/machines",
            {},
            body
          );
          }
         
          this.form = { ...EMPTY };
          this.$emit("confirm")
        }
        this.confirmLoading = false;
      });
    },
    handleCancel(e) {
      this.$emit("close")
    },
  },
};
</script>