<template>
  <a-space direction="vertical" size="large">
    <a-page-header title="维修记录" sub-title="" @back="() => null" />
    <div class="search-area" v-if="customer_id > 0">
      <a-button type="link" icon="plus" @click="handleCreate"
        >添加维修记录</a-button
      >
    </div>
    <a-input-group compact>
     
      <a-input
        addon-before="客户 ID"
        style="width: 30%"
        v-model="search.customer_id"
        default-value=""
      />
      <a-button @click="handleSearch" type="primary">搜索</a-button>
    </a-input-group>
    <a-table
      rowKey="id"
      :columns="columns"
      :data-source="data"
      :scroll="{ y: 500 }"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <span slot="indexNum" slot-scope="item, record, index">
        {{ index + 1 }}
      </span>
      <span slot="status" slot-scope="item">
        {{ MAINTAIN_STATUS_MAP[item] }}
      </span>
      <span slot="datetime" slot-scope="item">
        {{ new Date(item * 1000).toLocaleDateString() }}
      </span>
      <div slot="action" slot-scope="text, record">
        <div class="btns-column">
          <a-button
            @click="
              () => {
                handleEdit(record);
              }
            "
            :id="record.id"
            type="primary"
            >编辑</a-button
          >
        </div>
      </div>
    </a-table>
    <EditMaintain
      :visible="showEditor"
      :initForm="editForm"
      @close="handleEditorClose"
      @confirm="handleEditorConfirm"
    ></EditMaintain>
  </a-space>
</template>


<script>
import { request } from "@utils";
import EditMaintain from "../components/EditMaintain.vue";
import { MAINTAIN_STATUS_MAP } from "@/consts.js";
const columns = [

  {
    title: "客户 ID",
    dataIndex: "customer_id",
    key: "customer_id",
    width: 200,
    ellipsis: true,
  },
  {
    title: "状态",
    dataIndex: "status",
    key: "status",
    width: 100,
    scopedSlots: { customRender: "status" },
  },
  {
    title: "内容",
    dataIndex: "content",
    key: "content",
  },
  {
    title: "跟进人",
    dataIndex: "operator",
    key: "operator",
  },
  {
    title: "创建时间",
    dataIndex: "created_at",
    key: "created_at",
    ellipsis: true,
    width: 100,
    scopedSlots: { customRender: "datetime" },
  },
  {
    key: "action",
    align: "right",
    width: 120,
    fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  name: "MaintainManage",
  components: {
    EditMaintain,
  },
  props: {
    customer_id: String,
  },
  mounted() {
    this.refresh(0, 10, {
      customer_id: +this.customer_id,
    });
    this.search.customer_id = +this.customer_id
  },
  data() {
    return {
      MAINTAIN_STATUS_MAP,
      data: [],
      columns,
      search: {
        customer_id: "",
      },
      showEditor: false,
      editForm: {},
      pagination: {
        pageSize: 20,
        current: 1,
        total: 0,
      },
    };
  },
  methods: {
    handlePrint(record) {
      window.open(`/#/print/${record.machine_id}`);
    },
    handleCreate() {
      this.editForm = {
        customer_id: this.customer_id,
      };
      this.showEditor = true;
    },
    handleRemoveBtn() {},
    handleEditorConfirm() {
      this.showEditor = false;
      this.handleSearch();
    },
    handleEditorClose() {
      this.showEditor = false;
    },
    handleEdit(record) {
      this.editForm = {
        ...record,
      };
      console.log(record);
      this.showEditor = true;
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;

      this.refresh(
        pagination.pageSize * (pagination.current - 1),
        pagination.pageSize
      );
    },
    handleSearch() {
      this.refresh(0, 10, {
        ...this.search,
      });
    },
    async refresh(offset = 0, limit = 10, search) {
      const res = await request.get(
        `/api/cms/v1/maintains?offset=${offset}&limit=${limit}`,
        search
      );
      this.data = [];
      for (let item of res.data.data.list) {
        this.data.push({
          ...item,
        });
      }
      this.pagination.total = res.data.data.count
    },
  },
};
</script>

<style scoped>
.search-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btns-column button {
  margin: 5px;
}
</style>