export default {
  Set(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value))
  },
  Get(key) {
    let str = window.localStorage.getItem(key)
    if(null != str) {
      return JSON.parse(str)
    }
    return str
  },
  Delete(key) {
    window.localStorage.removeItem(key)
  }
}