<template>
  <a-space class="container">
    <a-card class="login-form">
      <img class="logo" src="../assets/logo.png" />
      <a-form-model
        layout="vertical"
        :model="formInline"
        @submit="handleSubmit"
        @submit.native.prevent
      >
        <a-form-model-item>
          <a-input
            size="large"
            v-model="formInline.phoneNo"
            placeholder="输入手机号"
          >
            <a-icon
              slot="prefix"
              type="phone"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-input-password
            size="large"
            v-model="formInline.password"
            placeholder="输入密码"
          >
            <a-icon
              slot="prefix"
              type="lock"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input-password>
        </a-form-model-item>
        <a-form-model-item>
          <a-button
            block
            size="large"
            type="primary"
            html-type="submit"
            :loading="loging"
            :disabled="formInline.phoneNo === '' || formInline.password === ''"
          >
            登录
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-card>
  </a-space>
</template>

<script>
import { request, storage } from "@utils";
import md5 from "md5";

export default {
  name: "Login",
  mounted() {
    if (this.$store.state.userInfo.token) {
      window.location = "/#/index";
    }
  },
  components: {},
  data() {
    return {
      loging: false,
      formInline: {
        phoneNo: "",
        password: "",
      },
    };
  },
  methods: {
    handleSubmit() {
      this.loging = true;
      request
        .post("/api/cms/v1/login", null, {
          mobile: `86 ${this.formInline.phoneNo}`,
          password: this.formInline.password,
        })
        .catch((e) => {
          this.loging = false;
          this.$error({
            title: "网络错误",
            content: e,
          });
        })
        .then((resp) => {
          this.loging = false;
          if (resp.data && !resp.data.errCode) {
            this.$message.success("登录成功");

            this.$store.commit("login", resp.data.data);
            storage.Set("login", resp.data.data);
            window.location = "/";
          } else {
            this.$error({
              title: "Error",
              content: resp.data.message,
            });
          }
        });
    },
  },
};
</script>

<style scoped >
.container {
  background: url(../assets/bg.jpeg) no-repeat;
  background-size: cover;
  padding: 1px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.logo {
  width: 120px;
  margin: 30px;
}
.login-form {
  text-align: center;
  width: 450px;
}
</style>
