<template>
  <a-modal
    title="用户信息"
    :visible="visible"
    :confirm-loading="confirmLoading"
    okText="确认"
    cancelText="取消"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form-model
      ref="skuForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item v-if="isEdit" label="用户ID" required prop="user_id">
        <a-input disabled v-model="form.user_id" />
      </a-form-model-item>
      <a-form-model-item label="姓名" required prop="nickname">
        <a-input  v-model="form.nickname" />
      </a-form-model-item>
      <a-form-model-item label="性别" required  prop="gender">
        <a-radio-group v-model="form.gender">
          <a-radio-button
            v-for="item in [0, 1, 2]"
            :key="item"
            :value="item"
          >
            {{ USER_GENDER_MAP[item] }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
       <a-form-model-item label="生日" required prop="birthday">
        <a-month-picker v-model="form.birthday" />
      </a-form-model-item>
      <a-form-model-item label="地址" required prop="location">
        <a-textarea v-model="form.location" />
      </a-form-model-item>
      <a-form-model-item label="联系方式" required prop="mobile">
        <a-textarea v-model="form.mobile" />
      </a-form-model-item>
      <a-form-model-item label="备注信息" prop="message">
        <a-textarea v-model="form.message" />
      </a-form-model-item>
      <a-form-model-item v-if="isEdit"  label="认证状态" prop="is_verify">
        {{ form.is_verify == 1 ? "已认证" : "未认证" }}
      </a-form-model-item>
      <a-form-model-item v-if="isEdit"  label="操作" prop="action">
        <a-button
          @click="
            () => {
              handleEditVerify();
            }
          "
          :id="form.user_id"
          type="primary"
          ><a-icon type="property-safety" />
          {{ form.is_verify == 1 ? "取消" : "" }}客户认证</a-button
        >
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from "@utils";
import { USER_GENDER_MAP } from "@/consts.js";

const EMPTY = {
  nickname: "",
  user_id: "",
  gender: 0,
  location: "",
  mobile: "",
  message: "",
};

export default {
  props: {
    visible: Boolean,
    initForm: Object,
  },
  components: {},
  watch: {
    visible: function (nv, ov) {
      console.log(nv, ov, this.initForm);
      if (nv == true && ov == false) {
        this.$refs.skuForm?.clearValidate();
        if (this.initForm) {
          this.form = {
            ...this.initForm,
            birthday: this.initForm.birthday  ? moment(this.initForm.birthday * 1000) : null
          };
        }
      }
    },
  },
  data() {
    return {
      USER_GENDER_MAP,
      isEdit: false,
      confirmLoading: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      form: {
        ...EMPTY,
      },
      rules: {
        // name: [{ required: true, message: "请填写商品名称" }],
        // goods_code: [{ required: true, message: "请填写商品代码" }],
        // price: [{ required: true, message: "请给商品定个价" }],
      },
    };
  },
  methods: {
    handleEditVerify() {
      request
        .put(
          `/api/cms/v1/users/${this.form.user_id}`,
          {},
          {
            is_verify: this.form.is_verify == 1 ? 0 : 1,
          }
        )
        .then(() => {
          this.$message.info("操作成功");
        });
    },
    handleGetSkuInfo(code) {
      // 商品码
      this.form.goods_code = code;
    },
    handleOk(e) {
      this.confirmLoading = true;
      this.$refs.skuForm.validate(async (valid) => {
        if (valid) {
          const isEdit = !!this.form.user_id;
          this.isEdit = isEdit
          let mutable = {};
          Object.keys(this.form).forEach((key) => {
            console.log(key, this.form[key], this.initForm[key]);
            if (this.form[key] != this.initForm[key]) {
              mutable[key] = this.form[key];
            }
          });
          if (mutable["birthday"]) {
            mutable["birthday"] = Math.ceil(
              new Date(mutable["birthday"]).getTime() / 1000
            );
          }
          if (mutable["gender"]) {
            mutable["gender"] = +mutable['gender']
          }
          
          const body = {
            ...mutable,
          };
          if (isEdit) {
            await request.put(
              `/api/cms/v1/users/${this.form.user_id}`,
              {},
              body
            );
          } else {
            await request.post("/api/cms/v1/users", {}, body);
          }

          this.form = { ...EMPTY };
          this.$emit("confirm");
        }
        this.confirmLoading = false;
      });
    },
    handleCancel(e) {
      this.$emit("close");
    },
  },
};
</script>