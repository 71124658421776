<template>
  <a-modal
    title="绑定信息"
    :visible="visible"
    :confirm-loading="confirmLoading"
    okText="确认"
    cancelText="取消"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form-model
      ref="skuForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="姓名" required prop="nickname">
        <a-input disabled v-model="form.nickname" />
      </a-form-model-item>
      <a-form-model-item label="设备名" required prop="machine_name">
        <a-input v-model="form.machine_name" />
      </a-form-model-item>
      <a-form-model-item label="设备信息" prop="machine_info">
        <a-input v-model="form.machine_info" />
      </a-form-model-item>
      <a-form-model-item label="状态" prop="status">
        <a-radio-group v-model="form.status">
          <a-radio-button
            v-for="item in [0, 1, 2, 3]"
            :key="item"
            :value="item"
          >
            {{ BOUND_STATUS_MAP[item] }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="维护周期" prop="maintain_cycle">
        <a-input type="number" v-model="form.maintain_cycle" />
      </a-form-model-item>
      <a-form-model-item label="下次维护时间" prop="next_maintain_at">
        <a-date-picker v-model="form.next_maintain_at" />
      </a-form-model-item>
      <a-form-model-item label="消息（客户可见）" prop="message">
        <a-textarea v-model="form.message" />
      </a-form-model-item>
      <!-- <a-form-model-item label="状态"  prop="status">
        <a-input type="number" v-model="form.status" />
      </a-form-model-item> -->
    </a-form-model>
  </a-modal>
</template>
<script>
import { request } from "@utils";
import { BOUND_STATUS_MAP } from "@/consts.js";
import moment from 'moment';
import 'moment/locale/zh-cn';
const EMPTY = {
  id: "",
  machine_name: "",
  satus: 0,
  model: "",
  nickname: "",
  message: "",
};

export default {
  props: {
    visible: Boolean,
    initForm: Object,
  },
  components: {},
  watch: {
    visible: function (nv, ov) {
      console.log(nv, ov, this.initForm);
      if (nv == true && ov == false) {
        this.$refs.skuForm?.clearValidate();
        if (this.initForm) {
          this.form = {
            ...this.initForm,
            status: "" + this.initForm.status,
            // maintain_at: this.initForm.maintain_at  ? moment(this.initForm.maintain_at * 1000) : null,
            next_maintain_at: this.initForm.next_maintain_at  ? moment(this.initForm.next_maintain_at * 1000) : null
          };
        }
      }
    },
  },
  data() {
    return {
      BOUND_STATUS_MAP,
      confirmLoading: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      form: {
        ...EMPTY,
      },
      rules: {
        // name: [{ required: true, message: "请填写商品名称" }],
        // goods_code: [{ required: true, message: "请填写商品代码" }],
        // price: [{ required: true, message: "请给商品定个价" }],
      },
    };
  },
  methods: {
    handleOk(e) {
      this.confirmLoading = true;
      this.$refs.skuForm.validate(async (valid) => {
        if (valid) {
          const isEdit = !!this.form.id;
          let mutable = {};
          Object.keys(this.form).forEach((key) => {
            console.log(key, this.form[key], this.initForm[key]);
            if (this.form[key] != this.initForm[key]) {
              mutable[key] = this.form[key];
            }
          });
          if (mutable["next_maintain_at"]) {
            mutable["next_maintain_at"] = Math.ceil(
              new Date(mutable["next_maintain_at"]).getTime() / 1000
            );
          }
          if (mutable["maintain_cycle"]) {
            mutable["maintain_cycle"] = +mutable["maintain_cycle"];
          }
          const body = {
            ...mutable,
            status: mutable.status === undefined ? undefined : +mutable.status,
          };

          if (isEdit) {
            await request.put(
              `/api/cms/v1/machines/bound/${this.form.id}`,
              {},
              body
            );
          } else {
            await request.post("/api/cms/v1/machines/bound", {}, body);
          }

          this.form = { ...EMPTY };
          this.$emit("confirm");
        }
        this.confirmLoading = false;
      });
    },
    handleCancel(e) {
      this.$emit("close");
    },
  },
};
</script>