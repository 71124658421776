
const UserInfo = {
  email: "",
      name: "",
      nonce: "",
      phone: "",
      realm: "",
      sessionId: "",
      shopId: "",
      shopName: "",
}
export default  {
  state: {
    userInfo:{
      ...UserInfo
    },
    count: 0
  },
  mutations: {
    login(state, userInfo) {
      if(null == userInfo) {
        state.userInfo = {
          ...UserInfo
        }
        return
      }
      state.userInfo = {
        ...state.userInfo,
        ...userInfo
      }
    },
    increment (state) {
      state.count++
    }
  }
}