<template>
  <a-modal
    title="客户信息"
    :visible="visible"
    :confirm-loading="confirmLoading"
    okText="确认"
    cancelText="取消"
    @ok="handleOk"
    @cancel="handleCancel"
    width=500
  >
    <a-form-model
      ref="skuForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item v-if="isEdit" label="用户ID" required prop="user_id">
        <a-input disabled v-model="form.user_id" />
      </a-form-model-item>
      <a-form-model-item label="姓名" required prop="full_name">
        <a-input  v-model="form.full_name" />
      </a-form-model-item>
      <a-form-model-item label="性别" required  prop="gender">
        <a-radio-group v-model="form.gender">
          <a-radio-button
            v-for="item in [0, 1, 2]"
            :key="item"
            :value="item"
          >
            {{ USER_GENDER_MAP[item] }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
       <a-form-model-item label="生日" required prop="birthday">
        <a-date-picker v-model="form.birthday" />
      </a-form-model-item>
      <a-form-model-item label="地址" required prop="address">
        <a-textarea v-model="form.address" />
      </a-form-model-item>
      <a-form-model-item label="联系方式" required prop="contact">
        <a-textarea v-model="form.contact" />
      </a-form-model-item>
      <a-form-model-item label="备注信息" prop="remark">
        <a-textarea v-model="form.remark" />
      </a-form-model-item>
       <a-form-model-item label="设备信息" prop="information">
        <a-input v-model="form.information" />
      </a-form-model-item>
      <a-form-model-item label="状态" prop="status">
        <a-radio-group v-model="form.status">
          <a-radio-button
            v-for="item in [0, 1, 2, 3]"
            :key="item"
            :value="item"
          >
            {{ CUSTOMER_STATUS_MAP[item] }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="预约时间" prop="reserved_at">
        <a-date-picker v-model="form.reserved_at" />
      </a-form-model-item>
      <a-form-model-item label="维护时间" prop="maintain_at">
        <a-date-picker v-model="form.maintain_at" />
      </a-form-model-item>
      <a-form-model-item label="维护周期" prop="maintain_cycle">
        <a-input type="number" v-model="form.maintain_cycle" />
      </a-form-model-item>
      <a-form-model-item label="下次维护时间" prop="next_maintain_at">
        <a-date-picker placeholder="不填则默认按照周期生成" v-model="form.next_maintain_at" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from "@utils";
import { USER_GENDER_MAP, CUSTOMER_STATUS_MAP } from "@/consts.js";

const EMPTY = {
  nickname: "",
  user_id: "",
  gender: 0,
  location: "",
  mobile: "",
  message: "",
};

export default {
  props: {
    visible: Boolean,
    initForm: Object,
  },
  components: {},
  watch: {
    visible: function (nv, ov) {
      console.log(nv, ov, this.initForm);
      if (nv == true && ov == false) {
        this.$refs.skuForm?.clearValidate();
        if (this.initForm) {
          this.form = {
            ...this.initForm,
            birthday: this.initForm.birthday  ? moment(this.initForm.birthday * 1000) : null
          };
        }
      }
    },
  },
  data() {
    return {
      USER_GENDER_MAP,
      CUSTOMER_STATUS_MAP,
      isEdit: false,
      confirmLoading: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      form: {
        ...EMPTY,
      },
      rules: {
        // name: [{ required: true, message: "请填写商品名称" }],
        // goods_code: [{ required: true, message: "请填写商品代码" }],
        // price: [{ required: true, message: "请给商品定个价" }],
      },
    };
  },
  methods: {
   
    handleGetSkuInfo(code) {
      // 商品码
      this.form.goods_code = code;
    },
    handleOk(e) {
      this.confirmLoading = true;
      this.$refs.skuForm.validate(async (valid) => {
        if (valid) {
          const isEdit = !!this.form.id;
          this.isEdit = isEdit
          let mutable = {};
          Object.keys(this.form).forEach((key) => {
            console.log(key, this.form[key], this.initForm[key]);
            if (this.form[key] != this.initForm[key]) {
              mutable[key] = this.form[key];
            }
          });
    

          for(let item of ['status', 'gender', 'maintain_cycle']) {
            if (mutable[item]) {
              mutable[item] = +mutable[item]
            }
          }
         
          
          const body = {
            ...mutable,
          };
          if (isEdit) {
            await request.put(
              `/api/cms/v1/customers/${this.form.id}`,
              {},
              body
            );
          } else {
            await request.post("/api/cms/v1/customers", {}, body);
          }

          this.form = { ...EMPTY };
          this.$emit("confirm");
        }
        this.confirmLoading = false;
      });
    },
    handleCancel(e) {
      this.$emit("close");
    },
  },
};
</script>